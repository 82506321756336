
import { defineComponent } from 'vue'
import BackDrop from '@/components/BackDrop.vue'
import { ObjPairBoolean } from '@/types/general'

export default defineComponent({
  name: 'Dialog',
  emits: ['close'],
  props: {
    useComponent: {
      type: Object,
      required: true,
    },
    componentProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isShowing: {
      type: Boolean,
      required: true,
    },
    size: {
      type: String,
      required: false,
      default: 'small', // 'small' | 'medium' | 'large' | 'fullScreen'
    },
    easyClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isVeryTall: false,
    }
  },
  computed: {
    dialogClasses(): ObjPairBoolean {
      return {
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
        SizeLarge: this.size === 'large',
        FullScreen: this.size === 'fullScreen',
        IsVeryTall: this.isVeryTall,
      }
    },
  },
  methods: {
    onBackdropClick() {
      if (!this.easyClose) {
        return
      }

      this.onCloseDialog()
    },
    onCloseDialog() {
      this.$emit('close')
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onPassToParent(event: { [key:string]: any }) {
      this.$emit(event.name, event.value)
    },
    onCheckHeight() {
      this.isVeryTall = false
      const { height } = (this.$refs.Dialog as HTMLDivElement).getBoundingClientRect()

      if (this.size === 'fullScreen') {
        return
      }

      if (height > window.innerHeight - window.innerHeight * 0.3) {
        this.isVeryTall = true
      }
    },
  },
  components: {
    BackDrop,
  },

})
